import React from "react"
import Layout from "../components/layout"
import { graphql as gql, PageProps } from "gatsby"
import useStoryblok from "../lib/storyblok"
import useStoryblokComponent from "../lib/useStoryblokComponent"
import Seo from "../components/seo"

const Contact: React.FC<PageProps<any>> = ({ data, location }) => {
  const story = useStoryblok(data.storyblokEntry, location)

  const StoryblokComponents = useStoryblokComponent(story)

  return (
    <Layout>
      <Seo title="Contact" />
      {StoryblokComponents}
    </Layout>
  )
}

export default Contact

export const query = gql`
  query Contact {
    storyblokEntry(full_slug: { eq: "contact" }) {
      name
      content
    }
  }
`
